.container {
  min-height: 100vh;
  .topWrap {
    display: flex;
    justify-content: space-between;
    button {
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 100px;
      border: 2px solid #131c31;
      background-color: #fff;
      color: #131c31;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.5px;
      margin: 10px 0px;
    }
  }

  .listWrap {
    margin-top: 30px;
    .listheader {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 600;
      background-color: #131c31;
      color: #fff;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 0px 30px;
      p:first-child {
        width: 40px;
      }
      p:last-child {
        width: 100px;
      }
    }
    .listPageWrap {
      border: 1px solid #d1d0d5;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 0px 30px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 10px 0px;
      margin: 0;
      cursor: pointer;
    }
    li:first-child {
      width: 40px;
    }
    li:last-child {
      width: 100px;
    }
  }
}
