.container {
  margin: 0 auto;
  display: flex;
  padding: 350px 0px;
  .wrap {
    width: 400px;
    margin: 0 auto;
    .titleWrap {
      text-align: center;
      img {
        width: 138px;
        height: 34px;
        margin: auto;
      }
      p {
        font-size: 15px;
        color: #abb1bb;
      }
      p:last-child {
        color: #141736;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.5px;
      }
      div {
        // width: 100%;
        height: 1px;
        background-color: #d1d0d5;
      }
    }
    .loginWrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: #141736;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.5px;
      .inputWrap {
        border: 1px solid #d1d0d5;
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 18px;
          height: 18px;
          padding: 10px;
        }
        input {
          padding: 15px 0px;
          outline: none;
          border: none;
          width: 100%;
        }
      }
      button {
        cursor: pointer;
        padding: 15px 15px;
        background: #141736;
        border: none;
        color: #fff;
        font-size: 18px;
      }
    }
    .bottomWrap {
      text-align: center;
      p {
        font-size: 12px;
        color: #abb1bb;
      }
    }
  }
}
