.container {
  // position: absolute;
  // bottom: 0px;
  max-width: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-top: 1px solid #efefef;
  background-color: #fff;
}
