.container {
  top: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  align-items: center;
  span {
    font-size: 20px;
    font-weight: 600;
    // padding: 30px;
  }
  img {
    width: 138px;
    height: 33.934px;
  }
  .menuWrap {
    display: flex;
    align-items: center;
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      gap: 15px;
      padding: 0px 30px;
      li {
        cursor: pointer;
        position: relative;
        color: #666;
        font-size: 15px;
        font-weight: 500;
        &:first-child {
          cursor: default;
        }
        &:last-child::after {
          display: none;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: -8px;
          transform: translateY(-50%);
          width: 1px;
          height: 12px;
          background-color: #dcdee1;
        }
      }
    }
  }
}
