.container {
  margin: 0 auto;
  min-height: 100vh;
  padding: 10px 20px;
  // background-color: #f1efe9;

  span {
    width: 80px;
    font-size: 16px;
    font-weight: 500;
  }

  .titleWrap {
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    img {
      width: 25px;
      height: 25px;
    }
  }

  .conditionGroupWrap {
    display: flex;
    flex-direction: column;
    border: 1px solid #ebebeb;
    border-bottom: none;
    .siteSelectWrap,
    .userSelectWrap,
    .filterWrap {
      display: flex;
      gap: 15px;
      & > label:first-child {
        width: 90px;
        min-width: 90px;
        background-color: #eff3fa;
        min-height: 45px;
        line-height: 45px;
        text-align: center;
        font-weight: 500;
        @media screen and (max-width: 545px) {
          padding: 10px 0;
        }
      }
      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
        @media screen and (max-width: 545px) {
          gap: 5px;
          padding: 10px 0;
        }
        @media screen and (max-width: 680px) {
          padding: 10px 0;
        }
      }
      & > div > label {
        cursor: pointer;
        width: 120px;
        &:first-child {
          width: 85px;
        }
        &:nth-child(2) {
          width: 162px;
        }
        @media screen and (max-width: 545px) {
          &:first-child {
            width: 120px;
          }
        }
      }
    }
  }

  .siteSelectWrap {
    border-bottom: 1px solid #ebebeb;
    & > div > label {
      @media screen and (max-width: 545px) {
        max-width: 120px;
      }
    }
  }

  .siteSelectWrap,
  .userSelectWrap,
  .filterWrap {
    [type='radio'],
    span {
      vertical-align: bottom;
      appearance: none;
      background: rgb(213, 213, 213);
      border: 0.3rem solid darken(#fff, 0%);
      outline: max(1px, 0.1em) solid rgb(213, 213, 213);
      border-radius: 50%;
      width: 1.25em;
      height: 1.25em;
    }
    [type='radio']:checked {
      outline: none;
      border: 0.4em solid #5573ee;
      background-color: #fff;
    }
    [type='radio']:focus-visible {
      outline: max(2px, 0.1em) dotted #5573ee;
      outline-offset: max(2px, 0.1em);
    }
    [type='radio']:hover {
      box-shadow: 0 0 0 max(4px, 0.2em) lightgray;
      cursor: pointer;
    }

    [type='radio']:hover + span {
      cursor: pointer;
    }
  }

  .filterWrap {
    margin-bottom: 0;
    [type='radio']:checked {
      border: 0.4em solid rgb(254, 186, 0);
    }
    [type='radio']:focus-visible {
      outline: max(2px, 0.1em) dotted rgb(254, 186, 0);
      outline-offset: max(2px, 0.1em);
    }
  }

  .companyDateConditionWrap {
    border: 1px solid #ebebeb;
    .includeWrap,
    .exceptWrap,
    .dateWrap {
      display: flex;
      & > span {
        width: 90px;
        min-height: 60px;
        line-height: 60px;
        text-align: center;
        background-color: #eff3fa;
        font-weight: 500;
      }
      .selectWrap,
      .dropboxWrap {
        flex: 1;
        padding: 12px 20px 0 20px;
      }
    }
  }

  .dateWrap {
    .dropboxWrap {
      background-color: #fff;
      .dropbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        max-height: 38px;
        padding: 0px 8px;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #d1d0d5;
        & > div:first-child {
          display: flex;
          align-items: center;
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .selectDateWrap {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      padding: 0px 10px;
      border: 1px solid #131c31;
      p:last-child {
        color: #abb1bb;
      }
    }
  }

  .btnWrap {
    margin-top: 30px;
    text-align: center;
    button {
      width: 100%;
      border: none;
      cursor: pointer;
      padding: 15px 0px;
      background-color: #131c31;
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: -0.5px;
    }
  }

  .resultWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .searchWordWrap {
      & > span {
        line-height: 35px;
        color: #4d4d4d;
        // color: #175fe8;
        color: #5573ee;
        background-color: rgb(255, 247, 220);
        padding: 5px 7px;
        border-radius: 50px;
        border: 1px solid rgb(250, 220, 125);
        margin-right: 5px;
        overflow: auto;
      }
      & > span:last-child {
        color: #131c31;
        background-color: transparent;
        border: none;
      }
    }
    .excelBtnWrap {
      .excelBtn {
        cursor: pointer;
        display: inline-block;
        border: 1px solid #ebebeb;
        background-color: #eff8f1;
        border-radius: 4px;
        padding: 0 10px;
        img {
          vertical-align: middle;
          width: 25px;
        }
      }
      a {
        height: 30px;
        line-height: 30px;
        text-decoration: none;
        color: #131c31;
        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
      a:hover {
        color: #016e3f;
      }
    }

    .listWrap {
      margin-bottom: 20px;
      .listTitleWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }
      .listScrollHeader {
        background-color: #131c31;
        color: #fff;
        font-weight: 600;
        font-size: 17px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border: 1px solid #ebebeb;
        ul {
          display: flex;
          padding: 10px 50px;
          margin: 0px;
          justify-content: space-between;
          li {
            list-style: none;
          }
          li:first-child {
            width: 60px;
          }
          li:last-child {
            width: 120px;
          }
        }
      }
      .listScrollWrap {
        overflow: scroll;
        max-height: 300px;
        padding-bottom: 10px;
        background-color: #fff;
        border: 1px solid #ebebeb;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        ul {
          display: flex;
          padding: 1px 50px;
          justify-content: space-between;
        }
        li {
          list-style: none;
          font-size: 15px;
          font-weight: 500;
        }
        li:first-child {
          width: 60px;
        }
        li:last-child {
          width: 120px;
        }
      }
    }

    .msgWrap {
      display: flex;
      border: 1px solid #ebebeb;
      & > span {
        background-color: #eff3fa;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 90px;
      }
      .sendTextWrap {
        position: relative;
        width: calc(100%);
        padding: 15px 20px;
        & > div {
          position: absolute;
          display: flex;
          align-items: center;
          bottom: 30px;
          right: 25px;
          background-color: rgb(254, 186, 0);
          padding: 10px;
          border-radius: 50px;
          img {
            width: 25px;
            height: 25px;
          }
        }
        .sendText {
          width: calc(100% - 10px);
          resize: none;
          border: 1.5px solid #d1d0d5;
          border-radius: 4px;
          padding: 10px 0 0 10px;
          font-size: 15px;
          outline-color: #131c31;
          &::placeholder {
            color: #abb1bb;
          }
        }
      }
    }

    .fileWrap {
      display: flex;
      justify-content: space-between;
      min-height: 102px;
      border: 1px solid #ebebeb;
      border-bottom: none;
      .fileTitleWrap {
        display: flex;
        align-items: center;
        & > span {
          background-color: #eff3fa;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          min-height: 102px;
          margin-right: 20px;
        }
        label {
          display: flex;
          align-items: center;
          gap: 5px;
          img {
            width: 25px;
            height: 25px;
          }
          cursor: pointer;
          background-color: rgb(254, 186, 0);
          color: #fff;
          padding: 0 20px;
          line-height: 40px;
          font-weight: 600;
          border-radius: 4px;
        }
        input {
          display: none;
        }
      }
      .fileCheckWrap {
        padding: 2.5px 20px 0 0;
        & > div {
          position: relative;
          min-width: 95px;
          max-width: 95px;
          max-height: 95px;
          img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
          }
          .closeBtn {
            cursor: pointer;
            position: absolute;
            right: 0;
            width: 24px;
            height: 24px;
            border-radius: 0px;
          }
        }
      }
    }
  }

  .countWrap {
    display: flex;
    justify-content: center;
    gap: 10px;
    div {
      display: flex;
      gap: 5px;
    }
    p {
      color: blue;
    }
    .countTrueWrap {
      p:nth-child(2) {
        color: blue;
      }
    }
    .countFalseWrap {
      p:nth-child(2) {
        color: red;
      }
    }
    .countOkWrap {
      p:nth-child(2) {
        font-weight: 500;
        color: red;
        // color: blue;
      }
    }
  }
}

.calendar {
  padding: 20px 30px;
  width: 100%;
  max-width: 360px;
  // border: none;
  border: 1px solid #ae3e19;
  :global(.react-calendar__navigation) {
    margin-bottom: 0;
  }
  :global(.react-calendar__navigation button) {
    &:first-child {
      // font-size: 15px;
    }
  }
  :global(.react-calendar__navigation__label__labelText) {
    //년월
    color: #232323;
    text-align: center;
    font-weight: 600;
    letter-spacing: -0.5px;
    font-family: Pretendard;
  }
  :global(.react-calendar__month-view__weekdays) {
    //요일
    color: #131c31;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 11px;
    :global(.react-calendar__month-view__weekdays__weekday) {
      padding: 0;
    }
    abbr[title] {
      text-decoration: none;
    }
  }
  :global(.react-calendar__month-view__days) {
    //일
    :global(.react-calendar__tile--now) {
      //오늘
      background-color: unset;
    }
    :global(.react-calendar__tile) {
      aspect-ratio: 1/1;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :global(.react-calendar__tile:disabled) {
      // background-color: unset;
      color: #757575;
    }
    :global(.react-calendar__tile--active) {
      background-color: #131c31;
      color: white !important;
      border-radius: 50%;
    }
  }
}
