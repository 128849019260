.container {
  position: fixed;
  width: 100%;
  max-width: 650px;
  top: 0;
  bottom: 0;
  padding: 130px 0px;
  left: 50%;
  transform: translateX(-50%);
  .closeWrap {
    background-color: #666666;
    cursor: pointer;
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 50px;
  }
  .listWrap {
    overflow: scroll;
    padding: 30px 50px;
    background-color: white;
    border: 1px solid #d1d0d5;

    .listHeader,
    .listPeople,
    .listMsg,
    .listImg {
      border-bottom: 1px solid #d1d0d5;
      padding: 10px 0px;
      p:nth-child(1) {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .listHeader {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        gap: 10px;
      }
      span:nth-child(3) {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .listPeople,
    .listMsg {
      div {
        overflow: scroll;
        height: 100px;
      }
    }
    .listImg {
      div {
        display: flex;
        gap: 5px;
        height: 300px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
