.container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 0px 0px 0px;
  font-weight: 600;
  div {
    cursor: pointer;
  }
  .tab {
    border: 2px solid #131c31;
    border-radius: 50px;
    padding: 7px 15px;
  }
  .nowTab {
    border: 2px solid #131c31;
    border-radius: 50px;
    padding: 7px 15px;
    background-color: #131c31;
    color: #fff;
  }

  .excelBtn {
    border: 2px solid #131c31;
    border-radius: 50px;
    padding: 7px 15px;
  }
}
